<template>
  <div class="home">
    <top-nav @menu_data="getMenuData" :isARClogo="isARClogo" />
    <div class="part1">
      <img src="./../../assets/imgs/app/arcanitePartner/p1.png" class="part1Pic"/>
      <div class="part1Title">
        Arcanite Partners
      </div>
      <div class="part1Content">
        Are you a financial planner, mortgage broker or conveyancer? Arcanite Partners offers a platform for professional associates in the property industry to collaborate with our client base consisting of property developers and real estate agents.
        <br>
        <br>
        Contact us to expand your professional network and advance your business opportunities by joining Arcanite Partners today.
      </div>
      <div style="margin:auto">
        <a v-if="!isID" href=" " onclick="Calendly.showPopupWidget('https://meetings.hubspot.com/william463?embed=true');
      return false;">
          <div class="part1Button">Book A Demo<img src='./../../assets/imgs/app/arcanite/vector.png' class="vector"></div>
        </a>
        <a v-else href=" " onclick="Calendly.showPopupWidget('https://calendly.com/erni-yap');
      return false;">
          <div class="part1Button">Book A Demo<img src='./../../assets/imgs/app/arcanite/vector.png' class="vector"></div>
        </a>
      </div>
    </div>
    <div class="part2RecoginizedIcons">
      <div class="part2-topcontent">
        <trusted></trusted>
      </div>
      <div class="part2-bottomcontent">
          <recognised></recognised>
      </div>
    </div>
    <div class="part2">
        <div class="part2Content">
            <img src="./../../assets/imgs/app/arcanitePartner/p2.png" class="p2"/>
            <div style="marginTop:-50px">
              <div style="text-align:left">
                  <img src="./../../assets/imgs/app/arcanitePartner/minicon1.png" class="pic"/>
              </div>
              <h1>Managing all your leads in one platform</h1>
              <p>Arcanite Partners Portal is a web-based platform designed and developed to track leads between referral partners and facilitate digital loan  submissions for Arcanite’s finance product Equimate. The portal provides the dedicated lead generation funnel via the fact find affiliate links. The inbuilt links provide the users more power to generate leads via social media and from referral partners, with all information merging into the Arcanite’s CRM.</p>
            </div>
        </div>
    </div>
    <other-product></other-product>
    <footer-nav />
    <menu-bar :isShow="isShow" @changeIsShow="changeShow" />
  </div>
</template>

<script>
// import OtherProduct from '../PC/OtherProduct.vue';
export default {
  data() {
    return {
      isID:false,
      showMenuFlag: "0",
      isShow: false,
      isARClogo: true,
      part5MiddleNumber:2,
      // part5LeftNumber:1,
      // part5RightNumber:3,
      part5All:3,
      pageContent:[
        {title:'View your projects your way',
          minicon:require('@/assets/imgs/app/arcanite/minicon1.png'),
          content:'View all your current projects with quick links to availability, specifications, sharable information, marketing material and VR property rendering. Customise how you view your home screen for efficient property searches.',
        //   subLine:null,
          bottomPic:require('@/assets/imgs/app/arcanite/p2.png'),
          bottomPicSize:{width:'343px',height:'334px'}},
          {title:'All of your contacts in the one place',
          minicon:require('@/assets/imgs/app/arcanite/minicon2.png'),
          content:'Instantly add and organise your contact list. Sort contacts into a range of varying categories and filter based on sales stage, client source and more.',
        //   subLine:null,
          bottomPic:require('@/assets/imgs/app/arcanite/p3.png'),
          bottomPicSize:{width:'375px',height:'395.2px'},
          subImg:[require('@/assets/imgs/app/arcanite/people1.png'),
          require('@/assets/imgs/app/arcanite/people2.png'),
          require('@/assets/imgs/app/arcanite/people3.png'),
          require('@/assets/imgs/app/arcanite/people4.png'),]},
          {title:'Track sales more efficiently than ever',
          minicon:require('@/assets/imgs/app/arcanite/minicon3.png'),
          content:'Track progress on all sales and filter searches based on current sales status. Sort by price and date, for efficient tracking and follow-ups.',
          subLine:['Filter and sort to suit your preferences','Oversee agent requests','View sales statistics for ongoing R&D'],
          bottomPic:require('@/assets/imgs/app/arcanite/p4.png'),
          bottomPicSize:{width:'343px',height:'304.5px'}},
          {title:'Never miss an update',
          minicon:require('@/assets/imgs/app/arcanite/minicon4.png'),
          content:'Organise and add task to share amongst your team. Catagorise each task for quick identification and use the inbuild calendar to ensure all responsibilities are kept on track and send out reminder notifications.',
        //   subLine:null,
          bottomPic:null,
          bottomPicSize:null,
          mini:[require('@/assets/imgs/app/arcanite/iconPeople.png'),
          require('@/assets/imgs/app/arcanite/iconCal.png')]
          },
          {title:'All access to everything that matters',
          minicon:require('@/assets/imgs/app/arcanite/minicon5.png'),
          content:'Access saved lists, reports, invoices, partner contacts, training material, company information and much more. All you need to know to make a sale is in the palm of your hand.',
        //   subLine:null,
          bottomPic:null,
          bottomPicSize:null,
          mini:[require('@/assets/imgs/app/arcanite/iconSlash.png'),
          require('@/assets/imgs/app/arcanite/iconBook.png'),
          require('@/assets/imgs/app/arcanite/iconSingle.png')]
          },
          ]
    };
  },
  created() {
    if(localStorage.getItem('country')=="id"){
      this.isID=true;
    }else{
      this.isID=false;
    }
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll, true);
    // this.handleScroll();
    // this.handleFirstPara();
  },
  methods: {
    goRouter(path) {
      this.$router.push(path);
    },
    getMenuData(val) {
      val == "1" ? (this.isShow = true) : (this.isShow = false);
    },
    changeShow() {
      this.isShow = false;
    },
    handleClickDisplay() {
      this.isMove = true;
    },
    changePic(p){
      this.part5MiddleNumber=p
    },
  },

  components: {
    "top-nav": (resolve) => require(["./TopNav.vue"], resolve),
    "footer-nav": (resolve) => require(["./FooterNav.vue"], resolve),
    "menu-bar": (resolve) => require(["./MenuBar.vue"], resolve),
    "trusted":(resolve) => require(["./Trusted.vue"], resolve),
    "recognised":(resolve) => require(["./RecognisedPart.vue"], resolve),
    // "more-features":(resolve) => require(["./MoreFeatures.vue"], resolve),
    "other-product":(resolve) => require(["./OtherProduct.vue"], resolve),
  },
};
</script>
<style lang="scss" scoped>
.home{
  margin: 0;
  text-align: center;
  display: inline-block;
  width:100vw;
  max-width: 428px;
  min-width: 320px;
  overflow: hidden;
}

.part1{
  margin: auto;
  width: 100%;
  // overflow-x: hidden;
  // height: 809px;
  padding-top: 100px;
  overflow: hidden;
  margin-bottom: 85px;
  
  .part1Pic{
    margin: auto;
    width: 100%;
    // height: 402px;
    // padding-left: 7px;
    margin-bottom: 55px;
  }
  .part1Title{
    padding:0 26px 0 26px;
    // width: 323px;
    height: 36px;
    flex-grow: 0;
    font-family: Poppins-Bold;
    font-size: 30px;
    // font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: #062440;
  } 
  .part1Content{
    padding:0 26px 0 26px;
    // width: 323px;
    // height: 120px;
    flex-grow: 0;
    font-family: Poppins;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: left;
    color: #314455;
    padding-top:10px;
  }
  .part1Button{
      // margin-top:30px ;
      margin:30px 26px 0 26px;
    width: 157px;
    height: 42px;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 10px 16px;
    border-radius: 5px;
    background-color: #1890ff;
    display: flex;
    height: 22px;
    flex-grow: 0;
    font-family: Poppins-Bold;
    font-size: 16px;
    // font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
  }
//   .part1ButtonText{
//   }
}

.vector{
  width: 22px;
  height: 22px;
  flex-grow: 0;
  object-fit: contain;
  margin-left: 10px;
}

.part2{
    margin: auto;
    width: 100%;
    overflow: hidden;
    padding-bottom: 20px;
    background-color: #f7f9fb;
}
.part2Content{
    width: 100%;
    .p2{
        width: 100%;
        margin-top: 16px;
        // height: 290.4px;
        // margin: 0 0 314.6px;
    }
    h1{
    flex-grow: 1;
    font-family: Poppins-Bold;
    font-size: 26px;
    // font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.23;
    letter-spacing: normal;
    text-align: left;
    color: #062440;
    // margin-bottom: 20px;
    // margin-left: 10px;
    margin: 0 26px 20px 26px;
    }
    p{
  // margin-left: 10px;
  margin: 0 26px 20px 26px;
  flex-grow: 1;
  font-family: Poppins;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: #314455;
    }
}

.pic{
  width: 80px;
  height: 80px;
  margin: 0 0 0 16px;
}

.ClientTest{
  .title{
    margin:auto;
    margin-top:50px;
    margin-bottom: 15px;
    width: 343px;
    height: 84px;
    flex-grow: 0;
    font-family: Poppins-Bold;
    font-size: 36px;
    // font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: normal;
    text-align: left;
    color: #062440;
  }
  img{
    margin: auto;
    width: 375px;
    height: auto;
    // display: flex;
    // flex-direction: column;
    // justify-content: flex-start;
    // align-items: flex-start;
    // gap: 20px;
    // margin: 5px 10px 24.4px 26px;
    // padding: 20px;
    // border-radius: 5px;
    // box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.05), 0 15px 30px 0 rgba(0, 0, 0, 0.07);
    // border: solid 1px #e6ebf2;
    // background-color: #fff;
  }
  .dogs{
    margin: auto;
    width: 70px;
    height: 10px;
    display: flex;
    .dogBlue{
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: #1890ff;
      display: inline-block;
      margin:0 10px 0 10px;
    }
    .dogWhite{
      width: 10px;
      height: 10px;
      background-color: #d8d8d8;
      border-radius: 50%;
      display: inline-block;
      margin:0 10px 0 10px;
    }
  }
}

.part5{
    margin: auto;
    width: 375px;
    height: 284px;
    background-image:url('./../../assets/imgs/app/arcanite/p5.png') ;
    background-size: 375px 284px;
    .title{
        margin: auto;
        padding-top: 26px;
        margin-bottom: 30px;
        width: 325px;
        // height: 160px;
        align-self: stretch;
        flex-grow: 0;
        font-family: Poppins-Bold;
        font-size: 26px;
        // font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.23;
        letter-spacing: normal;
        text-align: left;
        color: #fff;
    }
    .button{
        flex-grow: 0;
        font-family: Poppins-Bold;
        font-size: 16px;
        // font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        // line-height: 1.38;
        letter-spacing: normal;
        text-align: center;
        color: #fff;
        width: 171px;
        height: 42px;
        flex-grow: 0;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        // gap: 10px;
        padding: 5x;
        border-radius: 5px;
        background-color: #062440;
    }
}

.vector{
  width: 22px;
  height: 22px;
  flex-grow: 0;
  object-fit: contain;
  margin-left: 10px;
}

.part2RecoginizedIcons{
  margin: 0px auto;
  margin-bottom: 40px;
  // width: 1210px;
  display: flex;
  flex-direction: column;
  // justify-content: center;
  // align-items:center;
  gap: 30px;
}
</style>

